<template>
	<div  class="template" v-title="'实景样板间——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
        <div class="box">
		    <img class="bran" src="../image/template/header_pc.jpg" alt="" />
        </div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/template/header_mob.jpg"
				alt=""
			/>
		</div>
        <div class="content f-y-s-c">
			<div class="filter">
                <CaseCategory 
                    addressTitle="位置"
                    houTypeTitle="户型"
                    :showAddress="showAddress"
                    :showDesLv="false"
                    :showAreas="false"
                    :showStore="false" 
                    @callback="callback"
                    >
                </CaseCategory>
            </div>

            <div v-if="templateList.length" class="list">
                <div
                    v-for="(item,index) in templateList"
                    :key="index" 
                    class="list-item"
                    @click="navToDetail(item.id)"
                    
                    >
                    <img class="item-img" :src="item.coverUrl" alt="">

                    <div class="mask"></div>
                   <div class="header f-x-b-c">
                        <span class="text1">{{item.title}}</span>
                        <div @click.stop="openDialog" class="btn f-x-c-c">预约到店</div>
                   </div>
                   <img class="img" src="../image/index/index_sample.png" alt="">
                   <div class="bottom">
                        <HouseInfo :data="item" type="column"></HouseInfo>
                   </div>
                </div>
            </div>
            <el-empty v-else description="暂无数据" class="empty" ></el-empty>
            <!-- <Pagination
                :page-size="params.size"
                :current-page="params.current"
                :total="total"
                @current-change="changeCurrent"
            ></Pagination> -->
            <div v-if="params.size*params.current<total" class="more-btn" @click="handleMore">
                点击加载更多
            </div>
		</div>

		<!-- 底部 -->
		<Footer></Footer>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>

        <DialogForm ref="refDialogForm" adUnitCode="gfhj"></DialogForm>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import AboutNav from '../components/AboutNav.vue'
import HouseInfo from '../components/HouseInfo.vue'
import CaseCategory from '../components/CaseCategory.vue'
import { useSalesRoomDict,useHouseAreasDict,useHousetypeDict,useDesStyleDict,useDesRankDict } from '@/utils/frequent.js'
import {codeToText} from 'element-china-area-data'
import { getTemplateData } from '@/api/template.js'
import { getCaseData } from '@/api/case.js'
import {mapState} from 'vuex'
export default {
	components: {
		NavBar,
		Footer,
		AboutNav,
        CaseCategory,
        HouseInfo
	},
	data() {
		return {
            codeToText,
            showDiaInd:-1,
			templateList: [],
            total:0,
            params:{
                size:6,
                current:1, 
                salesRoomNo:'',
                houseType:'',
                designStyle:'',
            },
            ranksList:[],
            storeList:[],
            curDesCaseList:[],
		}
	},
    computed:{
        ...mapState(['curCityInfo']),
        showAddress(){
            console.log("this.curCityInfo",this.curCityInfo);
            if(this.curCityInfo.value=='00') return true
            else return false
        }
    },
    async mounted(){
        this.getTemplateList()
        this.ranksList = await useDesRankDict()
        this.storeList = await useSalesRoomDict()
    },
    watch:{
        async showDiaInd(newVal){
            try{
                if(newVal!='-1'){
                    const designerId = this.templateList[newVal].id
                    const res = await getCaseData({size:2,current:1,designerId})
                    if(res.code==0){
                        this.curDesCaseList = res.data.records
                    }else this.curDesCaseList = []
                }
            }catch(err){
                console.log(err);
                return this.curDesCaseList = []
            }
            
        },
    },
	methods: {
        openDialog(){
            this.$refs.refDialogForm.showDialog('store')
        },
        handleMore(){
            this.params.current+=1
            this.getTemplateList()
        },
        navToDetail(id){
            this.$router.push({
                path:'/template/detail',
                query:{
                    id,
                    salesRoomNo:this.params.salesRoomNo,
                    houseType:this.params.houseType,
                    designStyle:this.params.designStyle,
                }
            })
        },
        changeCurrent(data){
            this.params.current = data
            this.getTemplateList()
        },
        async getTemplateList(){
            try{
                const res = await getTemplateData(this.params)
                if(res.code==0){
                    this.templateList = [...this.templateList,...res.data.records]
                    this.total = res.data.total
                }
            }catch(err){
                console.log(err);
            }
        },
        callback(data){
            const {store,houseArea,designStyle,houseType,ranks,areaCode} = data
            if(store=='-1') delete this.params.salesRoomNo
            else  this.params.salesRoomNo = store

            if(houseArea=='-1') delete this.params.houseArea
            else  this.params.houseArea = houseArea

            if(ranks=='-1') delete this.params.ranks
            else  this.params.ranks = ranks
            
            if(designStyle=='-1') delete this.params.designStyle
            else this.params.designStyle = designStyle

            if(houseType=='-1') delete this.params.houseType
            else this.params.houseType = houseType

            if(areaCode=='-1') delete this.params.areaCode
            else this.params.areaCode = areaCode
            this.params.current = 1
            this.templateList = []
            this.getTemplateList()
        }

    },
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 899px) {
    .template {
        overflow-x: hidden;
		.box {
            display: none;
			
		}
        .box-mob{
            width: 100%;
			height: 160px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
            padding-bottom: 30px;
			.filter{
                width: 100%;
            }
            .list{
                margin-top: 25px;
                padding: 0 10px;
                box-sizing: border-box;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                // gap: 0 10px;
                .list-item{
                    cursor: pointer;
                    position: relative;
                    padding: 10px 10px;
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 250px;
                    border-radius: 9px;
                   
                    margin-bottom: 20px;
                    box-sizing: border-box;
                    background-size: 100% 100%;
                    object-fit: cover;
                    overflow: hidden;
                    .item-img{
                        transition: all .6s;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 9px;
                    }
                    .mask{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 250px;
                        background:rgba(34,16,65,0.25) ;
                        border-radius: 9px;
                    }
                    .header{
                        z-index: 9;
                        width: 100%;
                        .text1{
                            
                            font-weight: bold;
                            font-size: 18px;
                            color: #FFFFFF;
                            line-height: 18px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            width: 65%;
                        }
                        .btn:hover{
                            border: 2px solid #f6900c;
                            background: #f6900c;
                            color: #fff;
                        }
                        .btn{
                            width: 100px;
                            height: 30px;
                            
                            font-weight: bold;
                            font-size: 18px;
                            color: #FFFFFF;
                            line-height: 18px;
                            border-radius: 20px;
                            border: 1px solid #FFFFFF;
                        }
                    }
                    .img{
                        z-index: 9;
                        // width: 80px;
                        height: 90px;
                        object-fit: cover;
                        // opacity: 0.5;
                        // flex: 1;
                    }
                    .bottom{
                        z-index: 9;
                        width: 100%;
                    }
                }
            }
            .empty{
                height: 200px;
                width: 100%;
            }
            .more-btn{
                cursor: pointer;
                width: 140px;
                height: 40px;
                border-radius: 20px;
                border: 1px solid #6A6A6A;
                
                font-weight: 400;
                font-size: 16px;
                color: #666666;
                line-height: 40px;
                text-align: center;
                margin: 20px auto 0 auto;
            }
		}
    }
}
@media screen and (min-width: 900px) and(max-width:1359px) {
	.template {
		height: 100%;
        .box{
            width: 100%;
			height: 230px;
			// margin-top: 96px;
        }
        .box-mob{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 0 2% 70px 2%;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.filter{
                width: 100%;
                margin-top: 20px;
            }
            .list{
                margin-top: 50px;
                width: 100%;
                display: grid;
                justify-content: space-between;
                grid-template-columns: repeat(auto-fill,49%);
                // gap: 0 10px;
                .list-item:hover{
                    .item-img{
                        transform: scale(1.2);
                    }
                    .mask{
                        background:rgba(34,16,65,0.15) !important;
                    }
                    .header{
                        .btn{
                            border: 2px solid #753CBE;
                            color: #fff;
                            background: #753CBE;
                        }
                    }
                }
                .list-item{
                   
                    position: relative;
                    padding: 40px 30px;
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 610px;
                    border-radius: 18px;
                   
                    margin-bottom: 50px;
                    box-sizing: border-box;
                    background-size: 100% 100%;
                    object-fit: cover;
                    overflow: hidden;
                    .item-img{
                        
                        transition: all .6s;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 18px;
                    }
                    .mask{
                        cursor: pointer;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 610px;
                        background:rgba(34,16,65,0.25) ;
                        border-radius: 18px;
                    }
                    .header{
                        cursor: pointer;
                        z-index: 9;
                        width: 100%;
                        .text1{
                            
                            font-weight: bold;
                            font-size: 44px;
                            color: #FFFFFF;
                            line-height: 44px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            width: 70%;
                        }
                        .btn:hover{
                            background: #f6900c;
                            color: #fff;
                            border-color: #f6900c;
                        }
                        .btn{
                            
                            width: 220px;
                            height: 80px;
                            
                            font-weight: bold;
                            font-size: 36px;
                            color: #FFFFFF;
                            line-height: 36px;
                            border-radius: 45px;
                            border: 2px solid #FFFFFF;
                        }
                    }
                    .img{
                        
                        z-index: 9;
                        width: 258px;
                        height: 258px;
                        // opacity: 0.5;
                        // flex: 1;
                    }
                    .bottom{
                        z-index: 9;
                        width: 100%;
                    }
                }
            }
            .empty{
                height: 400px;
                width: 100%;
            }
            .more-btn{
                cursor: pointer;
                width: 421px;
                height: 111px;
                border-radius: 55px;
                border: 1px solid #6A6A6A;
                
                font-weight: 400;
                font-size: 40px;
                color: #666666;
                line-height: 111px;
                text-align: center;
                margin: 50px auto 0 auto;
            }
		}
	}
}
@media screen and (min-width: 1360px) {
	.template {
		height: 100%;
        .box{
            width: 100%;
			height: 230px;
			// margin-top: 96px;
        }
        .box-mob{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 0 180px 70px 180px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.filter{
                width: 100%;
                margin-top: 20px;
            }
            .list{
                margin-top: 50px;
                width: 100%;
                display: grid;
                justify-content: space-between;
                grid-template-columns: repeat(auto-fill,49%);
                // gap: 0 10px;
                .list-item:hover{
                    .item-img{
                        transform: scale(1.2);
                    }
                    .mask{
                        background:rgba(34,16,65,0.15) !important;
                    }
                    .header{
                        .btn{
                            border: 2px solid #753CBE;
                            color: #fff;
                            background: #753CBE;
                        }
                    }
                }
                .list-item{
                   
                    position: relative;
                    padding: 40px 30px;
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 610px;
                    border-radius: 18px;
                   
                    margin-bottom: 50px;
                    box-sizing: border-box;
                    background-size: 100% 100%;
                    object-fit: cover;
                    overflow: hidden;
                    .item-img{
                        
                        transition: all .6s;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 18px;
                    }
                    .mask{
                        cursor: pointer;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 610px;
                        background:rgba(34,16,65,0.25) ;
                        border-radius: 18px;
                    }
                    .header{
                        cursor: pointer;
                        z-index: 9;
                        width: 100%;
                        .text1{
                            
                            font-weight: bold;
                            font-size: 44px;
                            color: #FFFFFF;
                            line-height: 44px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            width: 70%;
                        }
                        .btn:hover{
                            background: #f6900c;
                            color: #fff;
                            border-color: #f6900c;
                        }
                        .btn{
                            
                            width: 220px;
                            height: 80px;
                            
                            font-weight: bold;
                            font-size: 36px;
                            color: #FFFFFF;
                            line-height: 36px;
                            border-radius: 45px;
                            border: 2px solid #FFFFFF;
                        }
                    }
                    .img{
                        
                        z-index: 9;
                        width: 258px;
                        height: 258px;
                        // opacity: 0.5;
                        // flex: 1;
                    }
                    .bottom{
                        z-index: 9;
                        width: 100%;
                    }
                }
            }
            .empty{
                height: 400px;
                width: 100%;
            }
            .more-btn{
                cursor: pointer;
                width: 421px;
                height: 111px;
                border-radius: 55px;
                border: 1px solid #6A6A6A;
                
                font-weight: 400;
                font-size: 40px;
                color: #666666;
                line-height: 111px;
                text-align: center;
                margin: 50px auto 0 auto;
            }
		}
	}
}
</style>
